#upload-file {
    position: absolute;
    visibility: hidden;
    width: 0;
}
.uploadImages {
    display: flex;
    width: 100%;
    background: #e4f3fd;
}
.uploadImageForm {
    align-items: center;
    margin: 0.75rem 0 !important;
    padding: 0.5rem 0;
    label {
        display: inline-block;
        margin-right: 0.5rem;
    }
}
.uploadBtn {
    button {
        position: relative;
    }
    i {
        font-size:1.8rem;
        vertical-align: middle;
    }
    span {
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    label {
        text-align: center;
        margin: 0 1rem;
        &.disableBtn {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    p.ui.button.redBtn {
        padding: 0.25rem 1rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        min-width: 10rem;
        justify-content: center;
    }
}

.previewImg {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .imgDiv {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-wrap: wrap;
        justify-content: center;
        .uploadImageWrapper {
            border: 1px solid #ccc;
            margin: 0.5rem;
            padding: 0.25rem;
            background: #f5f5f5;
        }
        span {
            display: inline-block;
            max-width: 15rem;
            margin: 0.5rem 1rem;
            padding: 0.5rem;
            position: relative;
            background: #ccc;
            i {
                position: absolute;
                top: 0.5rem;
                font-size: 1.75rem;
                right: 0.5rem;
                color: #fff5f5;
                background: #000;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
     > div {
        margin: 0 1rem;
        text-align: center;
     }
    img {
       max-width: 100%;
       max-height: 10rem;
    }
    label {
        display: inline-flex !important;
        input {
            margin-right: 0.35rem;
        }
    }
  
}
.ui.form {
    .grouped.fields.imgStatusOption {
        .field {
            margin: 0;
            label {
                margin-bottom: 0;
                display: inline-flex;
                input {
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

.attachmentImgMsg {
    font-style: italic;
    font-size: 0.75rem;
    font-family: 'HelveticaNeueBold';
    color: #c7112d;
    text-transform: initial;
}
.cameraCaptureClose {
    text-align: right;
    .closeSearchPoup {
        font-size: 1.4rem;
    }
}
.webCamDiv {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    align-items: center;
    width: 100%;
    button {
        margin-top: 1rem !important;
        max-width: 10rem;
        font-family: 'HelveticaNeueBold' !important;
        font-size: 0.9rem !important;
    }
    video {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 767px) {
    .uploadBtn {
        > div{
            display: flex;
            justify-content: center;
        }
        
    }
    .uploadImages {
        flex-direction: column;
    }
    .previewImg {
        .imgDiv {
            display: block;
            span {
                display: inline-block;
                width: 40%;
                vertical-align: middle;
            }
        } 
    }
    .uploadImageForm label {
        display: block;
        width: 100%;
    }
}