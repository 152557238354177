@import '../../views/common/common.scss';


.homePageWrapper {
    .ui.segment {
        background: none;
        box-shadow: none;
        border: 0;
       > .ui.grid {
            justify-content: space-between;
        }
    }
    .menuBox {
        display: flex !important;
        box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
        margin: 0.5rem;
        padding: 1em 1em;
        border-radius: .28571429rem;
        border: 1px solid rgba(34, 36, 38, .15);
        flex-direction: column;
        &:nth-of-type(1) {
            border-right: 1px solid #ccc;
        }
    }

    .informationBox {
        min-width: 12rem;
        height: 8rem;
        background: #c7112d;
        display: flex;
        flex-direction: column;
        color: #fff;
        font-family: Antonio-Regular;
        align-items: center;
        justify-content: center;
        margin: 0.5rem;
        cursor: pointer;
        h4 {
            font-size: 1.25rem;
            font-weight: normal;
            margin-bottom: 0;
            font-family: Antonio-Regular;
        }
        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: Antonio-Regular;
            font-size: 1.25rem;
           
            span {
                &:nth-of-type(1) {
                    font-size: 2rem;
                    padding: 0.5rem 0;
                }
                font-size: 1.25rem;
            }
        }
    }

    .infoBtnBox {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width:480px) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        .linkBtn {
            background: none;
            color: #c7112d;
            text-decoration: underline;
            text-underline-position: under;
            text-wrap: nowrap;
            transition: 0.15s ease-in;
            margin: 0.5rem 0;
            font-size: 1rem;
            font-family: HelveticaNeueBold;
            border: none;
            padding: 0;
            text-align: left;
            cursor: pointer;
            &:hover {
                transform: scale(1.05);
                color: #222;
            }
            
        }
        .infoBtnLink {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    .descriptionBox {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-basis: 100%;
        p {
            font-size: 1rem;
            font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: bold;
            text-align: center;
        }
        > div {
            text-align: right;
         
        }
        
    }
}
.searckSkuPopUp {
    width: 90%;
    max-width: 600px;
    .content {
        padding: 1rem;
         > div {
            padding:  3rem 0;
         }
    }
    .closeSearchPoup {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;
        i {
            font-size: 1.5rem;
            color: #222;
        }
    }
    p {
        font-size: 1.25rem;
        text-align: center;
        font-family: HelveticaNeueBold;
    }
    .searchBox {
        width: 100%;
        max-width: 90%;
        margin: auto;
        input {
            height: 3.5rem;
        }
        span {
            width: 5rem;
            i {
                font-size: 1.8rem;
            }
        }
    }
}
.scanBarcode {
    i {
        color: #222;
        font-size: 3.1rem;
    }
}
.searckSkuBoxWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    .noItemDiv {
        text-align: right;
        width: 90%;
        display: flex;
        flex-direction: column;
        a {
            text-decoration: underline;
        }
    }
}
@media (max-width:767px) {
    .homePageWrapper {
        .menuBox {
        flex-direction: column;
        text-align: center;
        border-bottom: 1px #ccc solid;
        }
        .infoBtnBox .linkBtn {
            font-size: 0.9rem;
        }
    .descriptionBox {
        p {
            font-size: 1rem;
            margin-bottom: 0;
        }
        >div {
            text-align: center;
            .linkBtn {
                font-size: 1.1rem;
                padding: 0.5rem;
            }
        }
        
    }
    }
    .homePageWrapper .informationBox {
        height: 8rem;
        min-width: 10rem;
        h4 {
            font-size: 1rem;
        }
        p {
            span {
                font-size: 1rem;
            }
        }
    }
}
@media(min-width:1025px) {
    .homePageWrapper {
        margin-top: 2.5rem;
    }
}
