@import '../../views/common/common.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
.loadFeedbackSearch {
    display: flex;
    padding: 0.5rem;
    flex-wrap: wrap;
    border-bottom: 1px solid #222;
 .fields {
    display: flex;
    align-items: center;
    margin: 0.5rem !important;
    label {
        font-size: 0.9rem;
    }
    > label {
        margin-right: 0.5rem;
        font-family: Arimo-Bold;
        font-size:1rem;
    }
    .ui.checkbox {
        margin-right: 1rem;
        font-family: Arimo-Bold;
        font-size:0.9rem;
    }
 }
 .dateWrapper {
    display: flex;
    flex-wrap: wrap;
     >div {
        margin: 0.5rem !important;
     }

 }
 .btnWrapper {
    display: flex;
    margin: 0.5rem;
    button {
        margin: 0 0.5rem;
    }
 }
}
.loadFeedbackUpload {
    margin-top: 0.5rem;
    .ui.form {
        label {
            font-family: Arimo-Bold;
            font-size:0.9rem;
            text-transform: capitalize;
        }
        textarea {
            resize: none;
        }
        .customSelectBox {
            width: 23rem;
        }
    }
    .btnWrapper {
        text-align: right;
        .redBtn {
            margin-left: 0.5rem;
        }
        button {
            font-size: 1.15rem;
        }
    }
}
.loadFeedBackHistory {
    .ui.loader {
        position: fixed;
        top:50%;
    }
}
.historyPageWrapper {
    .loadFeedBackHistory {
        .HistoryDataDetails {
            padding: 0;
        }
    }
}
.image-gallery {
    padding-top:1rem;
}
.closeImagePoup {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem;
    i {
        font-size: 1.5rem;
        cursor: pointer;
    }
}
.viewDetailsPopup {
    .detailsBlock {
        font-family: 'HelveticaNeueBold';   
        position: relative;    
    }
    h3 {
        max-width: 85%;
        @media (max-width:767px) {
            font-size: 1.4rem;
        }
    }
    .closePopupIcon {
        text-align: right;
        margin-bottom: 0.25rem;
        margin-top: -0.25rem;
        i {
            font-size: 1.5rem;
        }
    }
    .historyPageWrapper .ui.segment.mobileTable {
        border-bottom: 0;
    }
}
.imageGalleryPoup {
    .image-gallery-slide .image-gallery-description {
        background: rgb(0 0 0 / 74%);
        bottom: 0;
        color: #fff;
        left: 50%;
        line-height: 1;
        padding: 10px 20px;
        position: absolute;
        white-space: normal;
        transform: translateX(-50%);
    }    
}
.ui.modal.viewMsgPopup {
    text-align: center;
    p {
        font-size: 1.2rem;
    }
    button {
        padding: 0.25rem 0.75rem;
        margin-top: 1rem;
        background: #e0e1e2 none;
        font-family: 'HelveticaNeueBold';
        font-size: 1.1rem;
        color: #222;
        border: 1px solid #ccc;
        border-radius: 3px;
        cursor: pointer;
    }
}
@media (max-width:787px) {
    .loadFeedbackUpload {
        .btnWrapper {
            text-align: center;
            margin-top: 1rem;
        }
        .ui.form {
            border-bottom: 1px solid #000;
            padding-bottom: 1rem;
        }
    }
    .viewDetailsPopup {
        .tableInfoHeader {
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            h3{
                font-size: 1.1rem;
            }
        }
    }
}