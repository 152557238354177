@import '../../views/common/common.scss';

.browseCategoryWrapper {
    form {
        display: flex;
        flex-direction: column;
         >.fields{
            width:100%
         }
    }
    .submitClaimBtn {
       margin-top: 0;
    }
    .customSelectBox {
        svg {}
    }
}
.quickSearchWrapper {
    .submitClaimBtn {
        margin-top: 1.75rem;
        @media(max-width:767px) {
            margin-top: 0.5rem;
        }
    }
}
.directShipSearchWrapper {
    margin-top: 1rem;
    .ui.form  {
        margin-top: 1rem;
        .field {
            .selectVendor {
                font-family: 'HelveticaNeueBold';
                color: #c7112d;
                font-size: 1rem;
                text-decoration: underline;
                cursor: pointer;
            }
        }    
    }
    .vendorBox {
        opacity: 0.8 !important;
        background: #dddbdb !important;
    }
    .submitClaimBtn {
        margin-top: 1.75rem;
    }
    
}

.venderSearchPopup {
    .closeSearchPoup {
        text-align: right;
        span {
            font-size: 1.3rem;
        }
        
    }
    .submitClaimBtn {
        margin-top: 1.5rem;
    }
}
.venderSearchPopup {
    max-width: 45rem;
}
.hiddenDivRef {
    max-width: 90%;
    width: 42rem;
}