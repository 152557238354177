@import '../../views/common/common.scss';

.confirmationValue {
    p {
        display: inline-block;
        font-family: HelveticaNeueBold;
        font-size: 1.1rem;
        margin: 0;
    }
    span {
        display: inline-block;
        padding: 0.5rem;
        font-family: HelveticaNeueBold;
        color: #c7112d;
    }
}
.pendingItemWrapper {
    margin-top: 1rem;
    h4 {
        font-size: 1.25rem;
        margin-bottom: 0;
    }
     >div {
        margin: 1rem 0;
     }
     .historyPageWrapper .historyDataDetails .ui.table {
        td {
            &:last-child {
                min-width: 5rem;
            }
        }
     }
}
.closeConfirmatiomPopup {
    text-align: right;
    margin-bottom: 0;
    > span {
        font-size: 1.3rem;
    }
}
.viewDetailsPdfLink {
    text-align: center;
    font-family: HelveticaNeueBold;
    color: #2d7eaf;
    cursor: pointer;
}
.deleteItemPopup {
    max-width: 35rem;
    text-align: center !important;
    .redBtn {
        margin-left: 1rem;
    }
}
.submitConfirmationPopup {
    .popupExportIcons {
        position: relative;
        text-align: right;
    }
}
.editPloicyApopup {
    .closeSearchPoup {
        text-align: right;
        display: block;
        font-size: 1.4rem;
    }
    h4 {
        font-size: 1.3rem;
        margin-top: 0;
    }
}