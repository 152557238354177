@import '../../views/common/common.scss';


.createClaimForm {
    margin-top: 1rem;
    .ui.form {
        > .fields {
            margin-bottom: 0.5rem;
            &.commentError {
                flex-direction: column;
                p {
                    color: #c7112d;
                    padding-left: 0.5rem;
                }
            }
            .disabled.field {
                opacity: 1;
            }
        }
        label {
            font-family: Arimo-Bold;
            font-size: 0.9rem !important;
        }
        .text {
            font-size: 0.9rem;
        }
        .customDisabled {
            opacity: 1 !important;
            label {
                opacity: 1 !important;
            }
            > div {
                opacity: 1 !important;
                cursor: not-allowed;
            }
        }
    }
 .invoiceFieldGroup {
    display: flex;
    p {
        margin-bottom: 0;
        font-size: 0.7rem;
        font-family: Arimo-Bold;
    }
    .fields {
        margin: 0  1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        label {
            margin: 0 0.5rem;
        }
        input {
            cursor: pointer;
        }
    }
 }
}
.mandatoryField {
    color: #c7112d;
   &span {color: #c7112d;}
    > label {
        &::after {
            content: '*';
            display: inline-block;
            color: #c7112d;
        }
    }
}
.flexColDirection {
    flex-direction:  column !important;
}

.submitClaimBtn {
    justify-content: flex-end;
    display: flex;
    margin-top: 0.75rem;
    .ui.button {
        margin: 0 0.5rem;
    }
    
}
.ui.form .field {
    &.fieldMsgBox {
        p{
            color: #c7112d;
            font-family: 'HelveticaNeueBold';
            font-size: 0.7em;
            text-align: center;
        }
    }
}
.closeEditPopup {
    position: absolute;
    right: 1rem;
    top:1rem;
    font-size: 2rem;
}

.ui.form .fields>.field.customSelectBoxDiv {
    min-width: 23.5rem;
    position: relative; 
    > span {
        right: 4rem;
        position: absolute;
        z-index: 10;
        font-size: 1.5rem;
        bottom: 0.5rem;
    }
}
.backToPageLink {
    display: flex;
    justify-content: space-between;
    a {
        background: none;
        color: #c7112d;
        text-decoration: underline;
        text-underline-position: under;
        text-wrap: nowrap;
        transition: 0.15s ease-in;
        margin: 0.5rem 0;
        font-size: 1rem;
        font-family: HelveticaNeueBold;
        border: none;
        padding: 0;
        text-align: left;
        cursor: pointer;
    }
}
.ui.form .fields {
    &.qtyMsgBox {
        flex-direction: column;
        @media (max-width:767px) {
            width: 100% !important;
        }
        p {
            text-align: center;
            font-family: 'HelveticaNeueBold';
            font-size: 0.8rem;
            color: #9f3a38;
        }
    }
}
.serialNoIcon {
    position: relative;
    flex-direction: column !important;
    @media (max-width:767px) {
        width: 100% !important;
    }
    .field {
        @media (max-width:767px) {
            width: 100% !important;
        }
    }
    > span {
        position: absolute;
        right: 0.25rem;
        top:-0.25rem;
        i {
            padding: 0.5rem;
            font-size: 1rem;
            cursor: pointer;
            color: #c7112d;
        }
    }
    p {
        text-align: center;
        font-family: 'HelveticaNeueBold';
        font-size: 0.8rem;
        color: #9f3a38;
    }
}
.serialPoup {
    .ui.form {
        .fields.serialPoupFromGroup{
            flex-wrap: nowrap;
            .field>label {
               display: none;
           }
           &:nth-of-type(1) {
              .field>label {
                display: block;
                font-family: 'HelveticaNeueBold';
                background: #222;
                color: #fff;
                margin-top: 0.5rem;
                padding: 0.35rem;
                text-align: center;
               }
           }
       }
    }

    .closeSearchPoup {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .viewDetailsPdfLink {
            margin: 0 1rem;
            text-decoration: underline;
            text-transform: uppercase;
            font-size: 0.84rem;
        }
    }
    
}

.serialMsgPoup {
    max-width: 40rem;
    text-align: center;
    font-family: 'HelveticaNeueBold';
    p {
        text-align: center;
        margin-bottom: 0.5rem;
        color: #754c4c;
    }
    .closeSearchPoup {
        text-align: center;
    }
}
.infoMsgPoup {
    max-width: 40rem;
    .content {
        text-align: center;
    }
    .ui.button {
        margin: 0 0.5rem;
    }
}