@import '../../views/common/common.scss';
.claimHistoryDetails {
    .popupExportIcons {
        position: relative;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
.rfaClaimEditWrapper {
    margin-top: 1rem;
    .deleteItemDetails {
        padding: 0.5rem;
        display: flex;
        justify-content: flex-end;
        &::before {
            content: '';
        }
        p {
            color: #c7112d;
            font-family: 'HelveticaNeueBold';
            flex-grow: 1;
            span {
                display: inline-block;
                margin: 0 0.25rem;
                padding: 0 0.25rem;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 68%;
                    right: 0;
                    top: 50%;
                    background-color: #222;
                    transform: translateY(-50%);
                }
                
            }
        }
    }
}
.claimItemDetailsPopup {
    .popupExportIcons {
        position: relative;
    }
    .claimsDetailsBlock {
        display: flex;
        justify-content: space-between;
        p {
            margin: 0.5rem;
        }
    }
    .closeSearchPoup {
        text-align: right;
        span {
            font-size: 1.4rem;
        }
    }
}
.claimsDetailsBlock {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    p {
        margin: 0.5rem;
        font-family: "HelveticaNeueBold";
    }
}
.itemAdditionalInfo {
    h4 {
        background-color: #222;
        color: #fff;
        font-family: HelveticaNeueBold;
        padding: 0.5rem;
    }
    .infoList {

        color: #222;
        p {
            margin-bottom: 0.5rem;
            font-size: 0.85rem;
            strong {
                font-family: "HelveticaNeueBold";
            }
        }
        margin-bottom: 0.5rem;
    }
    .infoComment {
        max-height: 5rem;
        overflow: auto;
        word-wrap: break-word;
    }
}
.deletedItemWrapper {
    .deletedItemHeading {
        p {
            display: inline-block;
            margin-right: 1rem;
            font-family: 'HelveticaNeueBold';
        }
        margin-top: 1rem;
    }
    .popupExportIcons {
        position: relative;
        text-align: right;
        margin-top: -2rem;;
    }
}
