@import './common.scss';
.productDetailsWrapper {
    margin: auto;
    > section {
        max-width: 600px;
    }
    .comfirmBtnDiv  {
        text-align: right;
    }
    h3 {
        margin-bottom: 0.5rem;
        font-family: HelveticaNeueMedium;
    }
    .description {
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
}
.productDetailList {
    display: flex;
    list-style-position: inside;
    list-style-type: none;
    margin: 0 ;
    padding: 0;
    li {
        font-family: Arimo-Bold;
        padding-right: 1rem;
    }
}
.ui.button.redBtn {
    background: #c7112d;
    color: #fff;
    transition: 0.35s;
    &:hover {
        background: #a71616;
    }
}
.no_prdcts_display {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30rem;
}
h3 {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-family: HelveticaNeueMedium;
    margin-bottom: 0;
}
.searchDataWrapper {
    font-family: 'HelveticaNeueMedium';
    display: flex;
    justify-content: space-between;
    margin: 0.25rem 0;
    flex-wrap: wrap;
    .searchTypeDiv {
        display: flex;
        .ui {
            margin: 0 0.25rem;
        }
    }
    .ui.selection.dropdown {
        padding: 0.5em;
        min-height: 1em;
        min-width: 11rem;
        margin-bottom: 0.25rem;
        > .dropdown.icon {
            line-height: 1em;
            padding: 0.75em;
        }
        .menu>.item {
            font-size: 0.9rem;
            padding: 0.5rem !important;
        }
    }
    .ui.input {
        height: 2.1em;
    }
    .ui.input>input {
        padding: 0.35em;
        width: 11rem;
        padding-right: 2rem;
    }
    .fields {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0.5rem 0;
        @media (max-width:700px) {
            flex-wrap: wrap;
        }
        label {
            font-size: 1rem;
            padding-right:0.5em;
            @media (max-width:768px) {
                display: block;
            }
        }
    }
    .sortTypeDiv {
        display: flex;
        align-items: center;
        .fields {
            align-items: flex-end;
        }
    }
    .loadFeedbackSearchBlock {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
        @media (max-width:700px) {
            flex-wrap: wrap;
        }
    }
    .loadFeedbackSearchIcon {
        cursor: pointer;
        background: #c7112d;
        /* height: 2.1rem; */
        display: inline-block;
        vertical-align: middle;
        border-radius: 0.2rem;
        line-height: 1;
        padding: 0.5rem 0.5rem;
        margin-left: 0.5rem;
        color: #f8f8f8;
        font-family: HelveticaNeueBold;
        margin: 0.25rem;
        &.clearIcon {
            background: #a39b9b;
            i {
                padding: 0 0.3rem;
            }
        }
    }
}
.fa-times {
    cursor: pointer;
}
.popupExportIcons {
    position: absolute;
    right: 0;
    top:0;
}
#printicon {
    cursor: pointer;
}
.customLoader {
    position: fixed;
}
.exportIcons {
    margin-left: 0.5rem;
    > span {
        margin-right: 0.25rem;
        cursor: pointer;
    }
    span,i {
        font-size: 1.5rem;
    }
}
.paginationBlock {
    display: flex;
    justify-content: center;
}
.tableInfoHeader {
    display: flex;
    justify-content: space-between;
}
.ui.breadcrumb {
    a {
        font-family: 'HelveticaNeueBold';
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 0.9rem;
        padding: 0.35rem 0;
        &.activeBreadcrumb {
            color: #a71616;
        }
    }
}
.noData {
    font-family: 'HelveticaNeueBold';
    text-align: center;
    font-size: 1.5rem;
    margin-top: 3rem;
}
.itemCountInfo {
    font-family: 'HelveticaNeueBold';
    margin-bottom: 0.25rem;
}
#errorField {
    * {
        color: #9f3a38 !important;
    }
}
.customSelectBox#errorField {
 >div {
    > div{
        border-color: #e0b4b4 !important;
        background-color: #fff6f6 !important;
    }
 }
}
#errorFieldImg {
    border: 1px solid #e0b4b4;
    padding: 1rem 0;
    label {
        color: #9f3a38 !important;
    }
}
.errorDateField {
    border: 1px solid #e0b4b4;
    color: #9f3a38 !important;
     input {
        background-color: #fff6f6 !important;
     }
}
.scanBarcodeDiv {
    margin-bottom: 0.5rem;
}
.no_itemDisplay {
    color: #9f3a38;
    h4 {
        text-align: c;
        font-size: 1.5rem;
    }
}
.searchSkuWrapper {
    margin: auto;
    margin-top: 1rem;
}
@media (max-width:767px) {
    h3 {
        font-size: 1.5rem;
    }
    .productDetailsWrapper {
        .four.wide {
            text-align: center;
        }
        .comfirmBtnDiv  {
            text-align: center;
          .redBtn {
            width: 170px;

          }
        }
    }
    .ui.button.redBtn { 
        font-size: 1.15rem;
    }
}

@media(max-width:768px) {
    .searchDataWrapper {
        .ui.selection.dropdown {
            font-size: 0.8rem;
            min-width: 9rem;
        }
        .loadFeedbackSearchBlock {
            align-items: flex-end;
            .ui.input > input {
                font-size: 0.8rem;
                width: 8rem;
               
            }
        }
        .loadFeedbackSearchIcon {
            margin-top: 1.5rem;
        }
    }
}