@import '../../views/common/common.scss';
.heroHeader {
    background-color: #222;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    margin: 0 !important;
    .heroHeaderWrapper {
    display: flex;
    height: 100%;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
    }
    h3 {
        font-family: Franchise;
        color: #fff;
        font-size: 2rem;
        line-height: 1.9rem;
        margin: 0;
    }
    .header-top-left {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-right: 0.5rem;
        flex-direction: row;
    }
    .headerLogoImg {
        max-height: 100%;
        max-width: 15rem;
        background-image: url('../../images/NWHLogo.png');
        width: 12rem;
        padding-bottom: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        cursor: pointer;
    }
    .header-top-right {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .header_icons > div {
        vertical-align: middle;
    }
    .userInfoWrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header_user_icons_text {
        position: relative;
        padding: 0 0.5rem;
        margin-right: 1rem;
        text-align: right;
        font-size: 1rem;
        line-height: 1.1;
    }
    .header_user_icons_text p {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0;
        text-align: right;
        white-space: nowrap;
        font-family: HelveticaNeueMedium;
        line-height: 1;
        
    }
    .headerNameStore {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b9a8a8;
        padding: 0 1rem;
        margin-right: 1rem;
        p {
            color: #fff;
            font-family: HelveticaNeueBold;
            text-wrap: nowrap;
            font-size: 1rem;
            margin: 0;
        }
    }   
    .header_user_icons_text:after {
        content: "";
        width: 1px;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        right: 0;
    }
    .header_user_icons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        white-space: nowrap;
        gap:0.75rem;
        flex-wrap: wrap;

        p {
            color: #fff;
            padding: 0.25rem;
            margin-bottom: 0;
            font-family: HelveticaNeueMedium;
            cursor: pointer;
        }
        .fa-qrcode {
            font-size: 2.3rem;
            color: #fff;
            cursor: pointer;
        }
    }
    #user-menu {
        margin-top: 0 !important;
    }
}
.mainMenuWrapper {
    background: $red-bg-color;
    position: sticky;
    top: 0;
    z-index: 10;
    > div {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    padding: 0.5rem;
    a,.customMenuLink {
        color: #000;
        font-family: HelveticaNeueBold;
        font-size: 1rem;
        transition: 0.35s;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            color: #fff !important;
        }
        &.activeMenu {
            color:#fff !important;
        }
     }
    }
}

.modelPopup {
    max-width: 20rem;
    top: 10rem;
    h3 {
        margin: 0.5rem 0;
    }
    &.needHelpPopup {
        max-width: 50rem;
    }
  }
  .ui.modal.modelPopup>.content {
    background: #f2f2f2;
  }
  .modelPopup .close.icon {
    color: #000;
    top: 0.5rem!important;
    right: 0.5rem!important;
  }

  .ui.dropdown.userDropDownMenu {
    &::before {
        content: '';
        display: inline-block;
        background: url('../../images/user.png');
        background-size: contain;
        width: 2rem;
        padding-bottom: 2.5rem;
        background-repeat: no-repeat;
        background-position: center;
    }
    .text {
        width: 2rem;
        font-family: HelveticaNeueMedium;
        display: none;
    }
    > i.icon {
        margin-left: 0.35rem;
        color: #fff;
    }
    > div.menu {
        right: 0 !important;
        left: auto !important;
    }
    a, p{
        font-family: HelveticaNeueMedium;
        color: #222;
    }
}

.searchBox {
        position: relative;
        margin-right: 1rem;
        width: 16rem;
        max-width: 300px;
        span {
                position: absolute;
                display: flex;
                top: 0;
                right: 0;
                align-items: center;
                bottom: 0;
                width: 2.5rem;
                justify-content: center;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                background: #c7112d;
                i {
                    height: auto;
                    color: #ffcece;
                    font-size: 1.25rem;
                    line-height: 1;
                }
        }
        input {
            border: 1px solid #ccc;
            width: 100%;
            height: 2.5rem;
            border-radius: 4px;
            padding-right: 2.8rem;
            padding-left: 1rem;
            font-size: 1.15rem;
        }
    }
.scanBarcode {
    line-height: 1;
    margin: 0 0.5rem;
    img {
        width: 2.8rem;
        
    }
}

.backDropShadow {
    display: none;
}
.mobileMenuIcon {
    display: none;

    i {
        color: #fff;
        font-size: 2.6rem;
    }
}
.closeMenu {
    display: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
    i {
        font-size: 2.2rem;
    }
}
.navList {
    a,.customMenuLink {
        margin: 0 0.75rem;
        padding: 0 0.5rem;
    }
}
  @media (max-width:767px) {
    .heroHeader {
        .heroHeaderWrapper {
            flex-basis: 100%;
        }
        .header-top-right {
            justify-content: flex-end;
            align-items: flex-end;
            flex-basis: 100%;
        }
    } 
  }

  @media (max-width:767px) {
    .navList {
        display: flex;
        flex-direction: column;
        width: 300px;
        position: fixed;
        height: 100vh;
        background: #c7112d;
        padding-top: 3rem;
        opacity: 1;
        transform: translateX(-100%);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        top: 0;
        left: 0;
        z-index: 10;
        a,.customMenuLink {
            color: #222;
            border-bottom: 2px solid #222;
            padding:  1rem;
        }
        &#showMenu {
            transform: translateX(0);
            opacity: 1;
        }
    }
    .mobileMenuIcon,.closeMenu {
        display: block;
    }
    .backDropShadow {
        display: block;
        position: fixed;
        width: 100%;
        background: #222222ba;
        height: 100vh;
        z-index: 1;
        top:0;
        left:0;
    }
    .heroHeader {
        .heroHeaderWrapper {
            width: 100%;
        }
        .header-top-right {
            justify-content: flex-end;
        }
        .userInfoWrapper {
            width: auto;
        }
    } 
    .mainMenuWrapper {
        .gridContainer.mobileMenuContainer {
            padding: 0.5rem 0;
             .fresnel-container {
                width: 100%;
                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
             }
             .searchBox {
                margin-right: 0;
                span {
                    background-color: #222;
                 }
             } 
        }
        .mobileMenuIcon i {
            color: #222;
        }
    }
  }