
@import '../../views/common/common.scss';


.paginationBlock {
    .ui.secondary.pointing.menu {
        border-bottom: 0;
        .item {
            font-family: 'Franchise';
            &[type='pageItem'] {
                font-family: HelveticaNeueBold;
            }
        }
        .active.item {
            border-color: #c7112d;
            color: rgb(199 17 45);
        }
    }
}
.historyPageWrapper {
    .ui.segment {
        box-shadow: none;
        border: 0;
        padding: 0;
        &.mobileTable {
            padding: 0.5rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #ccc;
            padding-bottom: 1rem;
            padding-top: 1rem;
            > .ui.grid {
                .column {
                    font-family: HelveticaNeueBold;
                    font-size: 0.9rem;
                    .actionIcons {
                        text-align: right;
                    }
                }
            }
        }
       > .ui.grid {
            justify-content: space-between;
            padding:0;
        }
        .ui.grid>[class*="sixteen wide"].column{
            padding: 0;
        }
    }
    .titleBox {       
        box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
        border-radius: .28571429rem;
        border: 1px solid rgba(34, 36, 38, .15);
        color: #000;
        align-items: center;
        justify-content: space-between;
        h4{
            margin: auto;
            padding-right:5px;
        }
        &:nth-of-type(1) {
            border-right: 1px solid #ccc;
        }  
    }
    table {
        width:100%;
    }
    .titleButton{
       .linkBtn {
            background: none;
            color: #c7112d;
            transition: 0.35s;
            text-decoration: underline;
            text-underline-position: under;
        }
    } 
    .searchBoxContainer{
        margin-top:10px;
        padding:5px;
        align-items: center;       
        .clamButton{
            .linkBtn{
                background-color: #c7112d;
                color: #fff;
            }
        }
        .clamBox{ 
            .checkbox label{
                padding-left: 17px;
            }         
        }
    } 
    .historyDataDetails{
        
        .ui.grid .row{
            padding-top: 4px;
            padding-bottom: 4px;
            .column {
                word-wrap: break-word;
            }
        }
        &.sixteen.column {
            padding: 0;
        }
        .ui.table {
            thead th {
                background-color: #222;
                color: #fff;
                font-family: HelveticaNeueBold;
                text-align: left;
                padding-top: 0.5rem !important;
                padding-bottom: 0.25rem !important;
                padding: 0.5rem !important;
            }
            td{
                text-align: center;
                padding: 0.5rem;
                background: #fff;
                font-size: 0.85rem;
                font-family: 'HelveticaNeueBold';
                line-height: 1.1;
                text-align: left;
                padding-left: 0.5rem !important;
            }
            .orderStatusErrorMsg {
                td {
                    border-top: 0;
                    background-color: #ddd;
                    border-bottom: 2px solid #ccc;
                    p {
                        text-align: right;
                        font-family: 'HelveticaNeueBold';
                        font-size: 0.9rem;
                        color: #c7112d;

                    }
                }
            }
        } 
        .ui.segment {
            table {
                width: 100%;
            }
        }
        .gridEdit{
            text-align: right;
            .linkBtn {
                background: none;
                color: #c7112d;
                transition: 0.35s;
                text-decoration: underline;
                text-underline-position: under;
                font-size: 0.9rem;
            }
        }
        .ui.pagination.menu{
            margin-top: 10px;
        }

    }
    .viewDetailsLink {
        color: #2185d0;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }
}
.orderStatusErrorMsgText {
    text-align: right;
    font-family: 'HelveticaNeueBold';
    font-size: 0.8rem;
    color: #c7112d;
}
.commentInfoBox {
    overflow: auto;
    word-wrap: break-word;
    font-size: 0.8rem;
}
.actionIcons{
    span {
        display: inline-block;
        margin: 0.25rem;
        font-size: 1.15rem;
        cursor: pointer;
        i {
            color: #326389;
        }
        .fa-trash-alt {
            color: #a1021a;
        }
    }
}
.infiniteScrollTable {
    margin-top: 1rem;
    .ReactVirtualized__Table__headerColumn {
        color: #fff;
        font-family: HelveticaNeueBold;
        font-size: 0.9rem;
    }
    .ReactVirtualized__Table__headerRow {
        background: #222;
        border-radius: .28571429rem;
    }
    .ReactVirtualized__Table__headerTruncatedText {
        vertical-align: middle;
    }
    .ReactVirtualized__Table__row {
        border-bottom: 1px solid #ccc;
    }
    .viewDetailsLink {
        color: #2185d0;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }
}
.recalculateQty {
    .ui.input {
        max-width: 5rem;
    }
}
.serialNoDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    h4 {
        margin: 0;
    }
    span.fa-file-excel {
        font-size: 1.5rem;
        cursor: pointer;
    }
}
.statusMsgPoup {
    text-align: center !important;
    font-family: 'HelveticaNeueBold';
    color: #c7112d;
}

.claimHistoryWrapper .historyPageWrapper .historyDataDetails .ui.table {
td {
    &:last-child {
        min-width: 5rem;
    }
    &:nth-last-of-type(9) {
        max-width: 7rem;
    }
}
}

@media (max-width:767px) {
    .historyPageWrapper {
        .titleText{
            margin-bottom:4px;
            background-color: #e6e3e3;
            padding:8px 4px;
            display: block;
            h4{
                font-size: 0.9rem;
                float: left;
            }
            p{
                font-size: 0.75rem;
            }
        }
        .titleButton{
            .linkBtn {
                color:#c7112d;
                font-size: 0.9rem;
            }
        } 
        .searchBoxContainer{
            .clamBox{ 
                .checkbox label{
                    padding-left: 20px;
                }
                .input{
                    width:115px;
                }            
            }
            .checkList1{
                margin-bottom: 10px;
            }  
        }
        .ui.pagination.menu .item{
            padding: 6px 10px;
            min-width: 2rem;        
        }
           
       
    }
    .historyPageWrapper .informationBox {
        height: 13rem;
    }
}
@media(min-width:768px) {
    .historyPageWrapper {
        .titleBox{
            display: flex !important;            
            background-color:#e6e3e3 ;
            padding:4px;
        }
        .titleText{
            display: flex;
        }
        .searchBoxContainer{ 
            .clamBox{
               display: flex;
               align-items: center;
            }
       }
        
    }  
}
@media(min-width:1024px) {
    .historyPageWrapper {
        .searchBoxContainer{            
            display: flex;
            justify-content: space-between
       }
    }
     
}
// Media query for printer
@media print {
    @page {
        margin: 0.5cm; /* Adjust margin size as needed */
      }
}